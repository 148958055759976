import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { API} from "aws-amplify";
import * as mutations from '../graphql/mutations';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBTestimonial,
  MDBAvatar,
  MDBSelect,
MDBAnimation,MDBTypography, MDBBox,

 MDBFooter,
 MDBStepper,
 MDBStep,

 MDBDatePicker,
 MDBTable, MDBTableBody, MDBTableHead,
 MDBModal, MDBModalBody, MDBModalHeader
} from 'mdbreact';


 import PlacesAutocomplete from 'react-places-autocomplete';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import img1 from '../assets/IMG_3020.JPG'
import img2 from '../assets/IMG_2986.JPG'
import img3 from '../assets/wc.png'
import img4 from '../assets/heart.png'
import googleImg from '../assets/google.png'
import squareImg from '../assets/square.png'

import './home.css';

const google = window.google


var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = mm + '/' + dd + '/' + yyyy;

class ClassicFormPage extends React.Component {

   constructor(props) {
    super(props)
  this.state = {

    modal: false,
     optionsTrip: [
      {
        text: "One way",
        value: "One way"
      },
      {
        text: "Roundtrip",
        value: "Roundtrip"
      },

    ],
         optionsPatient: [
      {
        text: "Wheelchair",
        value: "Wheelchair"
      },
      {
        text: "Ambulatory",
        value: "Ambulatory"
      },

    ],
  collapseID: '',
  disabled: true,
      formActivePanel1: 1,
  formActivePanel1Changed: false,
    notes: '',
  broker:[],
  brokers:'',
    weekends:'',
  fname: '',
  lname: '',
  email: '',
  phone:'',
  repPhone:'954-661-9791',
  city: '',
  state: '',
  zip: '',
  address: '',
  address2: '',
  duration:'',
  distance: '',
  wheelchair: '',
  roundTrip: '',
  status:'new',
  price: 0,

  queryData: '',
  appointmentTime:'',
  appointmentDate:today,
  cost: 0,



  }



  this.handleChange = this.handleChange.bind(this)

  this.calcPrice = this.calcPrice.bind(this)




   }

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));




  componentDidUpdate(prevProps, prevState) {
      if (
        prevState.wheelchair !== this.state.wheelchair &&
        prevState.roundTrip !== this.state.roundTrip
      ) {

        console.log('Hello Hello Hello');
      }
    }
  componentDidMount() {
    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  goToPrice = () =>{

    document.getElementById('price').scrollIntoView();
  }
   goToBook = () =>{

    document.getElementById('book').scrollIntoView();
  }
  goToContact = () =>{

    document.getElementById('contact').scrollIntoView();
  }



swapFormActive = (a) => (param) => (e) => {
  this.setState({
    ['formActivePanel' + a]: param,
    ['formActivePanel' + a + 'Changed']: true
  });
}

handleNextPrevClick = (a) => (param) => (e) => {
if(param === 5){

  this.calcDistance();
}
  this.setState({
    ['formActivePanel' + a]: param,
    ['formActivePanel' + a + 'Changed']: true
  });
}

handleSubmission = () => {
  alert('Form submitted!');
}

calculateAutofocus = (a) => {
  if (this.state['formActivePanel' + a + 'Changed']) {
    return true
  }
}

getCheckValue = value => {

    this.setState({ wheelchair: value[0] });



  };
  getCheck2Value = value => {


      this.setState({ roundTrip: value[0] });



  };


  getPickerValue = value => {
    this.setState({ appointmentTime: value });
    //console.log(value);
  };
  getPickerDateValue = value => {
    this.setState({ appointmentDate: value });
    //console.log(value);
  };
  getFNValue = value => {
    //console.log(value);
    this.setState({fname: value});

  };

  getNotesValue = value =>{
    this.setState({notes: value});

  }
  getLNValue = value => {
    //console.log(value);
    this.setState({lname: value});

  };
  getPhoneValue = value => {
    //console.log(value);
    this.setState({phone: value});

  };
  getEmailValue = value => {
    //console.log(value);
    this.setState({email:value});

  };
handleChange = address => {



  this.setState({ address });


};
handleChange2 = address2 => {


  this.setState({ address2 });

};





calcPrice = ()=>{

  if(this.state.wheelchair === 'Wheelchair' && this.state.roundTrip === 'Roundtrip')
{

this.state.price = (this.state.distance * 2) * 2 + 80

}
else if(this.state.wheelchair === 'Ambulatory' && this.state.roundTrip === 'One way'){

this.state.price = (this.state.distance * 2) + 40



}
else if(this.state.wheelchair === 'Wheelchair' && this.state.roundTrip === 'One way'){

this.state.price = (this.state.distance * 2) + 40

}
else if(this.state.wheelchair === 'Ambulatory' && this.state.roundTrip === 'Roundtrip'){
this.state.price = (this.state.distance * 2) * 2 + 80


}

 }


 calcPrice2 = ()=>{

  if(this.state.wheelchair === 'Wheelchair' && this.state.roundTrip === 'Roundtrip')
{

this.state.price = (this.state.distance * 3) * 2 + 120

}
else if(this.state.wheelchair === 'Ambulatory' && this.state.roundTrip === 'One way'){

this.state.price = (this.state.distance * 3) + 60



}
else if(this.state.wheelchair === 'Wheelchair' && this.state.roundTrip === 'One way'){

this.state.price = (this.state.distance * 3) + 60

}
else if(this.state.wheelchair === 'Ambulatory' && this.state.roundTrip === 'Roundtrip'){
this.state.price = (this.state.distance * 3) * 2 + 120


}

 }




   calcDistance =()=> {
     if(this.state.address === '' || this.state.address2 === '' || this.state.address == null || this.state.address2 == null)
     {
       alert('Please add a to and from destination. ')
       return;
     }


   let service = new google.maps.DistanceMatrixService();
   var mythis = this;
   service.getDistanceMatrix({
       origins: [this.state.address],
       destinations: [this.state.address2],
       travelMode: google.maps.TravelMode.DRIVING,
       unitSystem: google.maps.UnitSystem.METRIC,
       avoidHighways: false,
       avoidTolls: false
   }, function (response, status) {
     //console.log(Math.round(parseInt(response.rows[0].elements[0].distance.text)/1.609 * 100)/100)

     if (status === google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status !== "ZERO_RESULTS") {
      mythis.state.distance = Math.round(parseInt(response.rows[0].elements[0].distance.text)/1.609 * 100)/100;
      mythis.state.duration = response.rows[0].elements[0].duration.text;
      if(mythis.state.weekends === true)
      {
      mythis.calcPrice2();
      }else{

         mythis.calcPrice();
      }

        } else {
                    alert("Unable to find the distance via road.");
                }
   })




  }



newCustomer = event =>{
  event.preventDefault();

  const newCustomer = {
    fname: this.state.fname,
    lname: this.state.lname,
    phoneNumber: this.formatPhoneNumber(this.state.phone),
    emailAddress: this.state.email,
    address: this.state.address

  };

   API.graphql({ query: mutations.createCustomer, variables: {input: newCustomer}}).then(()=>{


   alert('Customer Added! ')
   window.location.reload();
  });


}

submitTrip = event =>{
  event.preventDefault();

  const newTrips = {
    fname: this.state.fname,
    lname: this.state.lname,
    address: this.state.address,
    address2: this.state.address2,
    wheelchair: this.state.wheelchair,
    roundtrip: this.state.roundTrip,
    appointmentTime: this.state.appointmentTime,
    appointmentDate: this.state.appointmentDate.toLocaleString('en-US', {   month: '2-digit', day: '2-digit',
    year: 'numeric'}),
    status: this.state.status,
    phoneNumber: this.state.phone,


};

  API.graphql({ query: mutations.createTrip, variables: {input: newTrips}}).then(()=>{




this.sendText();
this.sendText1();
  alert('Your request has been submitted! ')
 // window.location.reload();
 window.location.href = 'https://www.fivegtransportation.com/';


} );

}


validateForm = () =>{

  if(this.state.fname !== '' && this.state.lname !== '' && this.state.phone !== '' ){

    this.setState({
      disabled: false
    });
  }else if(this.state.fname === '' && this.state.lname === '' && this.state.phone === ''){

    this.setState({
      disabled: true
    });
  }

}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });


}



sendText = _ => {

  fetch(`https://zhvzmdpj5b.execute-api.us-east-2.amazonaws.com/dev/items?recipient=${this.state.phone}&textmessage=A rep will contact you to confirm availability shortly. Date: ${this.state.appointmentDate.toLocaleString('en-US', {   month: '2-digit', day: '2-digit' ,
  year: 'numeric'})} Time: ${this.state.appointmentTime}`)
  .catch(err => console.error(err))
}

sendText1 = _ => {

  fetch(`https://zhvzmdpj5b.execute-api.us-east-2.amazonaws.com/dev/items?recipient=9546619791&textmessage=${this.state.appointmentDate.toLocaleString('en-US', {   month: '2-digit', day: '2-digit',
  year: 'numeric'})} ${this.state.appointmentTime} ${this.state.fname} ${this.state.lname} ${this.state.address} ${this.state.address2} ${this.state.wheelchair} ${this.state.roundTrip} ${this.state.phone}`)
  .catch(err => console.error(err))
}




  render() {
    const { collapseID } = this.state;
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('navbarCollapse')}
      />
    );
    return (


      <HelmetProvider>

      <div id='classicformpage'>

        <Router>

        <div className="application">
            <Helmet>


            </Helmet>

        </div>

          <div className="red-skin">

            <MDBNavbar
              dark
              expand='md'
              scrolling
              fixed='top'
              style={{ marginTop: '0px' }}
            >

              <MDBContainer>
                <MDBNavbarBrand  >
                <strong className='text-dark'>F G Wheelchair Transport</strong>


                </MDBNavbarBrand>

                <MDBNavbarToggler
                  onClick={this.toggleCollapse('navbarCollapse')}
                />
                <MDBCollapse id='navbarCollapse'  isOpen={collapseID} navbar>
                  <MDBNavbarNav right  >
                    <MDBNavItem >
                      <MDBNavLink to='#!' onClick={this.goToPrice} className='text-dark' >Home</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to='#!' onClick={this.goToPrice} className='text-dark'>Our Prices</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem active>
                      <MDBNavLink to='#!'  onClick={this.toggle} className='red'>Book Transportation </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to='#!' onClick={this.goToContact} className='text-dark'>Contact Us </MDBNavLink>
                    </MDBNavItem>

                  </MDBNavbarNav>

                </MDBCollapse>
              </MDBContainer>
            </MDBNavbar>
            {collapseID && overlay}
          </div>
        </Router>



        <MDBView id='view1'>

        <MDBMask className='d-flex justify-content-center align-items-center gradient'  />
        <MDBCard className="my-1 px-5 pb-1"  style={{ height: '100%', width: '100%', paddingTop: '10rem' }}>
      <MDBCardBody>
      <section>

  </section>
        <MDBRow>
        <MDBCol lg="2">
            <MDBView className="z-depth-2 mb-lg-0 mb-4" hover waves>

              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="8">
            <a href="tel:+1-904-503-1963" className="black-text bold">
              <h6 className="font-weight-bold mb-3">
                <MDBIcon icon="ambulance" className="pr-2" />
               Wheelchair Transportation
               <MDBBox  mb={2} className="blockquote"> <cite title="Source Title">904-503-1963</cite></MDBBox>
              </h6>
            </a>

            <MDBTypography blockquote className="text-left">
            <MDBBox tag="p" mb={0}> F G Wheelchair Transportation mission is to provide mobility assistance to the elderly, mobility impaired and wheelchair-bound residents with superb quality wheelchair transportation in North Florida with the highest care and lowest possible cost.</MDBBox>
            <MDBBox tag="p" mb={0}>Our wheelchair transportation division has the highest standard of safety and professionalism in the industry. Our vans are fully equipped, insured and all drivers are properly trained.</MDBBox>
</MDBTypography>


         <MDBBtn onClick={this.toggle} rounded outline  color='red'  >
            <MDBIcon icon="ambulance" />  Schedule Online
                </MDBBtn><br/><br/>
                <img src={img3} className='img-fluid shadow-4'  alt='wheelchair' />
          </MDBCol>
          <MDBCol lg="2">
            <MDBView className="z-depth-2 mb-lg-0 mb-4" hover waves>

              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>




          <MDBContainer
            style={{ height: '100%', width: '100%', paddingTop: '10rem' }}
            className='mt-5  d-flex justify-content-center align-items-center'
            id='contact'
          >


            <MDBRow>
              <MDBAnimation
                type='fadeInLeft'
                delay='.3s'
                className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5'
              >
                <h1 className='h1-responsive font-weight-bold text-dark text-center'>
                Non-Emergency Medical Transportation Service
                </h1>
                <a href="tel:+1-904-503-1963"> <h3 className='text-dark text-center'> (904) 503-1963</h3></a>
                <hr className='hr-dark' />
                <h4 className='mb-4 text-dark text-center'>
                  Medical Transportation<br/>
                  Dialysis Transportation<br/>
                Transportation for Seniors<br/>
                Transportation for Worship Service<br/>

Servicing Hospitals, Nursing Homes, Residential and more.<br/>
Professional, Safe and Reliable Service<br/>

Servicing Clay & Duval County<br/>
Commercial Licensed & Insured<br/>
                </h4>
                <div className='text-center'>
                <MDBBtn rounded outline  color='red' onClick={this.toggle} >
                 Schedule Transportation <MDBIcon icon="ambulance" />
                </MDBBtn>
                </div>
              </MDBAnimation>

              <MDBCol md='6' xl='5' className='mb-4'>


                <MDBContainer>
      <div className="text-nowrap bd-highlight" id='spaceBetween' >

      </div>
    </MDBContainer>
    <img className="img-fluid"src={img1}  alt="wheelchair-lift" />

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBView>

        <MDBContainer id='price'>
     <section className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold text-center my-5">
       Transportation Prices
      </h2>
      <p className="grey-text text-center w-responsive mx-auto mb-5">
        The rates listed below are based on a one-way trip. (Round trip will be the load fee x2 plus the mileage rate times the round-trip mileage.)
      </p>
      <MDBRow>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard>
            <MDBCardBody>
              <h5 className="mb-4">AMBULATORY TRANSPORTATION</h5>
              <div className="d-flex justify-content-center">
                <div className="card-circle d-flex justify-content-center align-items-center">
                  <MDBIcon fas icon="car" className="light-blue-text" />
                </div>
              </div>
              <h2 className="font-weight-bold my-4">$40/Load Fee </h2>
              <p className="grey-text">
                Plus $2 Per Mile

              </p>
              <MDBBtn rounded color="light-blue"onClick={this.toggle}>
                Schedule Now
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard className="young-passion-gradient">
            <MDBCardBody className="white-text">
              <h5 className="mb-4">WHEELCHAIR TRANSPORTATION</h5>
              <div className="d-flex justify-content-center">
                <div className="card-circle d-flex justify-content-center align-items-center">
                  <MDBIcon fas icon="truck" className="light-blue-text" />
                </div>
              </div>
              <h2 className="font-weight-bold my-4">$40/Load Fee </h2>
              <p>
                Plus $2 Per Mile
              </p>
              <MDBBtn outline rounded color="white" onClick={this.toggle}>
                Schedule Now
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard>
            <MDBCardBody>
              <h5 className="mb-4">AFTER HOUR TRAVEL</h5>
              <div className="d-flex justify-content-center">
                <div className="card-circle d-flex justify-content-center align-items-center">
                  <MDBIcon fas icon="shuttle-van" className="light-blue-text" />
                </div>
              </div>

              <h2 className="font-weight-bold my-4">$60/Load Fee </h2>
              <p className="grey-text">
             Plus $3 Per Mile
              </p>
              <MDBBtn rounded color="light-blue" onClick={this.toggle}>
                Schedule Now
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
        </MDBContainer>

        <MDBView id='view0'>

         <MDBContainer id='book0'>

         <h2 className="text-center font-weight-bold pt-4 pb-5 mb-2"><strong>More About Us</strong></h2>
         <MDBRow>

           <MDBCol md="8">
         <MDBTypography blockquote className="text-left">
            <MDBBox tag="p" mb={0}>
Our company will strive to meet these needs by providing the best of training, the highest standards of client sensitivity, quality vehicles designed for the comfort of our passengers, and superior opportunities for our Employees. We further promise in the event of any emergency conditions or disasters, to respond immediately to the needs of the healthcare community.</MDBBox>
         <p className="text-justify">Five G Transportation provides door-through-door non-emergency transportation throughout Clay and Duval Counties, servicing both government and private sectors. We offer personalized, affordable patient transportation, including handicapped services.

Our experienced drivers are well trained to deliver safe and comfortable rides. We also have rigorous safety training, including CPR. Our support staff demonstrates honesty, integrity and accountability.

We love being part of this community and helping people. Five G Transportation is based on the belief that every single ride to be safe, comfortable and pleasant. We are also proud to be members of the Veteran Owned Small Business Association.

  </p>
  </MDBTypography>
  </MDBCol>
  <MDBCol>
  <img src={img4} className='img-fluid shadow-4'  alt='disabled assistance' />
  </MDBCol>


</MDBRow>
           </MDBContainer>

           </MDBView>

         <MDBView id='view2'>
          <MDBMask className='d-flex justify-content-center align-items-center ' />
         <MDBContainer id='book'>
    <h2 className="text-center font-weight-bold pt-4 pb-5 mb-2"><strong>Customer Reviews</strong></h2>




        <section className="text-center my-5">

        <MDBCarousel
          activeItem={1}
          length={3}
          slide={true}
          showControls={true}
          multiItem
          testimonial
        >
          <MDBCarouselInner>
            <MDBRow>
              <MDBCarouselItem itemId="1">
                <MDBCol md="4">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={squareImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Square Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      Couldn’t ask for better service. Ken went above and beyond. Thank you very much.
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
                <MDBCol md="4" className="clearfix d-none d-md-block">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={squareImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Square Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      Thank you for your service
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
                <MDBCol md="4" className="clearfix d-none d-md-block">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={squareImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                     Square Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      Thank you for the seamless way this transaction was done! I have your card and will definitely recommend you
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon  icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBCol md="4">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                       src={squareImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Square Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      We love Five G Transportation and highly recommend them!!!
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
                <MDBCol md="4" className="clearfix d-none d-md-block">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={squareImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Square Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      Security and professionalism
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
                <MDBCol md="4" className="clearfix d-none d-md-block">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={squareImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Square Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      On time and professional
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBCol md="4">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={googleImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Google Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                     Very reliable and punctual! Thanks guys.
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
                <MDBCol md="4" className="clearfix d-none d-md-block">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                        src={googleImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Google Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      Best service by far. Very professional, and courteous and I will definitely be using they're services again in the future
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
                <MDBCol md="4" className="clearfix d-none d-md-block">
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto">
                      <img
                         src={googleImg}
                        alt=""
                        className="rounded-circle img-fluid"
                      />
                    </MDBAvatar>

                    <h6 className="blue-text font-weight-bold my-3">
                      Google Review
                    </h6>
                    <p className="font-weight-normal">
                      <MDBIcon icon="quote-left" className="pr-2" />
                      Awesome service!
                    </p>
                    <div className="grey-text">
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                      <MDBIcon icon="star" />
                    </div>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
            </MDBRow>
          </MDBCarouselInner>
        </MDBCarousel>
      </section>







      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle}>Request Transportation</MDBModalHeader>
        <MDBModalBody>
        <MDBStepper icon>
        <MDBStep far icon="user" stepName="Basic Information" onClick={this.swapFormActive(1)(1)}></MDBStep>
        <MDBStep  icon="map-marked" stepName="Pickup" onClick={this.swapFormActive(1)(2)}></MDBStep>
        <MDBStep  icon="map-marked-alt" stepName="Destination" onClick={this.swapFormActive(1)(3)}></MDBStep>
        <MDBStep icon="address-card" stepName="Trip Details" onClick={this.swapFormActive(1)(4)}></MDBStep>
        <MDBStep icon="check" stepName="Finish" onClick={this.swapFormActive(1)(5)}></MDBStep>
      </MDBStepper>


        <div className='text-left'>
        <MDBRow>
          {this.state.formActivePanel1 === 1 &&
          (<MDBCol md="12">
            <h3 className="font-weight-bold pl-0 my-4 text-center">
              <strong>Customer Information</strong></h3>
              <form
          className="was-validated"

          noValidate
        >
            <MDBInput  icon='user'  onChange={this.validateForm} value={this.state.fname} getValue={this.getFNValue} label="First Name" className="mt-4" autoFocus={this.calculateAutofocus(1)}  required />
            <MDBInput icon='user'  onChange={this.validateForm} value={this.state.lname} getValue={this.getLNValue} label="Last Name" className="mt-4"   required/>
            <MDBInput icon='phone'  onChange={this.validateForm} value={this.state.phone} getValue={this.getPhoneValue} label="Cell Phone Number" className="mt-4"   required/>


            <MDBBtn color="mdb-color" disabled={this.state.disabled} rounded className="float-right" onClick={this.handleNextPrevClick(1)(2)}>next</MDBBtn></form>

          </MDBCol>)}


          {this.state.formActivePanel1 === 2 &&
          (<MDBCol md="12">
            <h3 className="font-weight-bold pl-0 my-4 text-center"><strong>Pickup Address</strong></h3>
            <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}



      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <MDBInput
              {...getInputProps({
                placeholder: 'Pickup Address ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

            <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(1)(1)}>previous</MDBBtn>
            <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(1)(3)}>next</MDBBtn>
          </MDBCol>)}

          {this.state.formActivePanel1 === 3 &&
          (<MDBCol md="12">
            <h3 className="font-weight-bold pl-0 my-4 text-center"><strong>Destination Address</strong></h3>
            <PlacesAutocomplete
        value={this.state.address2}
        onChange={this.handleChange2}


      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <MDBInput
              {...getInputProps({
                placeholder: 'Destination Address ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
            <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(1)(2)}>previous</MDBBtn>
            <MDBBtn color="mdb-color" rounded className="float-right"  onClick={this.handleNextPrevClick(1)(4)}>next</MDBBtn>
          </MDBCol>)}


          {this.state.formActivePanel1 === 4 &&
          (<MDBCol md="12">

            <h2 className="text-center font-weight-bold my-4">Trip Details</h2>



<div className='text-center red-text'>  <label htmlFor="formGroupInput">Appointment Date</label>

<MDBDatePicker  id="datePicker" getValue={this.getPickerDateValue} />
</div>
<MDBSelect
          options={this.state.optionsTrip}
          selected="Choose trip type"
          label="Trip type "
           value={this.state.roundTrip} getValue={this.getCheck2Value}
        />
                <MDBSelect
          options={this.state.optionsPatient}
          selected="Choose patient type"
          label="Patient Type "
          value={this.state.wheelchair} getValue={this.getCheckValue}
        />
            <MDBInput label='Appointment Time' type='time' id="timePicker" value={this.state.appointmentTime}    getValue={this.getPickerValue} />

            <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(1)(3)}>previous</MDBBtn>
            <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(1)(5)}>Next</MDBBtn>
          </MDBCol>)}

          {this.state.formActivePanel1 === 5 &&
          (<MDBCol md="12">

            <h2 className="text-center font-weight-bold my-4">Trip Summary</h2>

            <div className="text-center">

            <h4 className="my-4">Est Time: <div className='red-text'>{this.state.duration}</div></h4>
            <h4 className="my-4">Est Miles: <div className='red-text'>{(this.state.roundTrip === 'Roundtrip') ? this.state.distance * 2 : this.state.distance}</div></h4>
            <h4 className="my-4">Est Cost: <div className='red-text'>${ Math.round(this.state.price * 100)/100}</div></h4>
          <MDBBtn color="danger" rounded onClick={this.handleNextPrevClick(1)(5)}>Calculate Price</MDBBtn>
            </div>

            <div className="text-center">

             <MDBTable responsive>



<MDBTableHead>
  <tr>
    <th>First Name</th>
    <th>Last Name</th>
    <th>phoneNumber</th>
    <th>Pickup Address</th>
    <th>Destination Address</th>
    <th>Appointment Time</th>
    <th>Appointment Date</th>


  </tr>
</MDBTableHead>
<MDBTableBody>
  <tr>
    <td>{this.state.fname}</td>
    <td>{this.state.lname}</td>
    <td>{this.state.phone}</td>
    <td>{this.state.address}</td>
    <td>{this.state.address2}</td>
    <td>{this.state.appointmentTime}</td>
    <td>{this.state.appointmentDate.toLocaleString('en-US', {   month: '2-digit', day: '2-digit',year: 'numeric'})}</td>


  </tr>

</MDBTableBody>
</MDBTable></div>


            <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(1)(4)}>previous</MDBBtn>
            <MDBBtn color="primary" rounded className="float-right" onClick={this.submitTrip}>Submit Request</MDBBtn>
          </MDBCol>)}
        </MDBRow>
        </div>
        </MDBModalBody>

      </MDBModal>

    </MDBContainer>   </MDBView>

         <MDBFooter id='footer' className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">

      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:   <a href="index.html">FIVE G TRANSPORTATION</a><br/>
           <a href="tel:+1-904-503-1963"> <font color='red'> (904) 503-1963</font></a>
        </MDBContainer>
      </div>
    </MDBFooter>
      </div>
      </HelmetProvider>
    );
  }
}

export default ClassicFormPage;