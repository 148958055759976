
import './App.css';
import Amplify from 'aws-amplify'
//import config from './aws-exports'
import ClassicFormPage from './components/home'

const myAppConfig = {

  "aws_project_region": "us-east-2",
  "aws_appsync_graphqlEndpoint": "https://46toxrygmrgh3a6w4qrmwolvty.appsync-api.us-east-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-2",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-gtoprve44zatbf2nfkakzh5l6y",
  "aws_cloud_logic_custom": [
    {
        "name": "apia842769d",
        "endpoint": "https://khriag3t3f.execute-api.us-east-2.amazonaws.com/dev",
        "region": "us-east-2"
    }
],
"aws_cognito_identity_pool_id": "us-east-2:07fd3199-df0f-4d91-98ec-2a33972a6e9c",
"aws_cognito_region": "us-east-2",
"aws_user_pools_id": "us-east-2_zO2ZFnc5c",
"aws_user_pools_web_client_id": "4bmq5v8guf8tvute3tqqudkhtp",
"oauth": {}

}

Amplify.configure({
  //...config,
  ...myAppConfig,
  Analytics: {
    disabled: true,
  },
});

function App() {
  return (
    <div className="App">
  <ClassicFormPage/>
    </div>
  );
}

export default App;
